// * .TTF或.OTF，适用于Firefox 3.5、Safari、Opera
// * .EOT，适用于Internet Explorer 4.0+
// * .SVG，适用于Chrome、IPhone

@font-face {
    font-family: 'en-Manrope-Regular';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/cf5e7fe79807bc30dab376b17cf30df2.woff2') format('woff2'), url('/fonts/Manrope/Manrope-Regular.woff') format('woff'),
        url('/fonts/Manrope/Manrope-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/Manrope-Regular.ttf') format('truetype'),
        url('/fonts/Manrope/Manrope-Regular.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-Bold';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/b977e4c21f8ece1ef9533b05b74be644.woff2') format('woff2'), url('/fonts/Manrope/Manrope-Bold.woff') format('woff'),
        url('/fonts/Manrope/Manrope-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/Manrope-Bold.ttf') format('truetype'),
        url('/fonts/Manrope/Manrope-Bold.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-ExtraBold';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/20c2727586938b4f0dd261efb5502904.woff2') format('woff2'), url('/fonts/Manrope/Manrope-ExtraBold.woff') format('woff'),
        url('/fonts/Manrope/Manrope-ExtraBold.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype'),
        url('/fonts/Manrope/Manrope-ExtraBold.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-ExtraLight';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/0cd27f321a3aaf8beb4c531af39eb4bc.woff2') format('woff2'), url('/fonts/Manrope/Manrope-ExtraLight.woff') format('woff'),
        url('/fonts/Manrope/Manrope-ExtraLight.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/Manrope-ExtraLight.ttf') format('truetype'),
        url('/fonts/Manrope/Manrope-ExtraLight.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-Light';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/6164d6eda310220df4420822271ada67.woff2') format('woff2'), url('/fonts/Manrope/Manrope-Light.woff') format('woff'),
        url('/fonts/Manrope/Manrope-Light.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/Manrope-Light.ttf') format('truetype'),
        url('/fonts/Manrope/Manrope-Light.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-Medium';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/87a7424ded3cbcea0e8017dfdd157fcd.woff2') format('woff2'), url('/fonts/Manrope/Manrope-Medium.woff') format('woff'),
        url('/fonts/Manrope/Manrope-Medium.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/Manrope-Medium.ttf') format('truetype'),
        url('/fonts/Manrope/Manrope-Medium.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-SemiBold';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/5571747b019eda63e734d8c33adf93c5.woff2') format('woff2'), url('/fonts/Manrope/Manrope-SemiBold.woff') format('woff'),
        url('/fonts/Manrope/Manrope-SemiBold.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/Manrope-SemiBold.ttf') format('truetype'),
        url('/fonts/Manrope/Manrope-SemiBold.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Dekko-Regular';
    src: url('https://websiteoss.ecoflow.com/fonts/Dekko/11328261787a1c312fa6ff78af67a700.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Bebas-Regular';
    src: url('https://websiteoss.ecoflow.com/fonts/LandingPage/6f6f82d9243d8111301f39648fb1d62b.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// 新字体 en/de/fr/it/es 2023-09-04
@font-face{
    font-family: 'EN-HelveticNeue-woff2-EB';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/605c36402d16df6ce198ebf0d1aa172d.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-B';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/9e1a68a37975c8f2499df3c809dedc85.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-EM';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/ac056a010bf44acdbcc0922addfb5010.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-M';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/8d454c0abbe5546de71999d0397bfae0.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-EL';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/4455ec462f289a3011dda0ad316f8029.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-L';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/f609858ca6bb3c8ca0dfa340325b9562.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


// 2024/12/11 Ossis页面 @bobo说是钟工和家源的需求 需要用到新字体inter
@font-face{
    font-family: 'EN-Inter-B';
    src: url('/fonts/Inter/Inter_24pt-Bold.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-Inter-S';
    src: url('/fonts/Inter/Inter_24pt-SemiBold.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-Inter-M';
    src: url('/fonts/Inter/Inter_24pt-Medium.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
