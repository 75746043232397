// * .TTF或.OTF，适用于Firefox 3.5、Safari、Opera
// * .EOT，适用于Internet Explorer 4.0+
// * .SVG，适用于Chrome、IPhone

// 针对“日文”样式的调整，必须写在全局样式的根结点中

// 字体地址:https://gwfh.mranftl.com/fonts/noto-sans-jp?subsets=japanese,latin

// 日文站很少使用加粗体，直接引用粗体
/* noto-sans-jp-100 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-Light';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-100.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-100.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/7f680b0e3e6c06d0af87ebadba274468.woff2') format('woff2'),
        /* Super Modern Browsers */ url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/b1ec1f0181f0bb3f432926da27203043.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-100.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-300 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-ExtraLight';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-300.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/fcfed0437b8e5494df25ad45a158f33a.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-300.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-300.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-regular - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-Regular';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/86fe8ce489ba722b7016ec47320ae9d6.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/912cef891894d66dd7186ab8e05b885c.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-Medium';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/86fe8ce489ba722b7016ec47320ae9d6.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/912cef891894d66dd7186ab8e05b885c.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-500 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-SemiBold';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-500.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/f49ac211a04c6a93ac940f877e8efdbb.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-500.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-500.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-700 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-Bold';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-700.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/d64fa73d0d6c25ff20ba685ff1c70123.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-700.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-700.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-900 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-ExtraBold';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-900.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/f26fe547fc7eaa81f24b17ada82970a4.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-900.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-900.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}


// 2023-09-04
@font-face{
    font-family: 'JP-NOTOSANS-woff2-ExtraBold';
    font-weight: 900;
    src: url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/f26fe547fc7eaa81f24b17ada82970a4.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-Bold';
    font-weight: 700;
    src: url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/d64fa73d0d6c25ff20ba685ff1c70123.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-SemiBold';
    font-weight: 500;
    src: url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/f49ac211a04c6a93ac940f877e8efdbb.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-Medium';
    font-weight: 400;
    src: url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/f49ac211a04c6a93ac940f877e8efdbb.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-Regular';
    font-weight: 300;
    src: url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/86fe8ce489ba722b7016ec47320ae9d6.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-Light';
    font-weight: 200;
    src: url('https://websiteoss.ecoflow.com/fonts/NOTOSANSJP/fcfed0437b8e5494df25ad45a158f33a.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}