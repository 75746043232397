// 2023-09-04 新字体
@font-face{
    font-family: 'RU-NOTOSANS-woff2-ExtraBold';
    src: url('/fonts/WOFF2_NotoSans_RU_MEAR/NotoSans-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'RU-NOTOSANS-woff2-Bold';
    src: url('/fonts/WOFF2_NotoSans_RU_MEAR/NotoSans-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'RU-NOTOSANS-woff2-SemiBold';
    src: url('/fonts/WOFF2_NotoSans_RU_MEAR/NotoSans-Medium.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'RU-NOTOSANS-woff2-Medium';
    src: url('/fonts/WOFF2_NotoSans_RU_MEAR/NotoSans-Regular.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'RU-NOTOSANS-woff2-Regular';
    src: url('/fonts/WOFF2_NotoSans_RU_MEAR/NotoSans-Light.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'RU-NOTOSANS-woff2-Light';
    src: url('/fonts/WOFF2_NotoSans_RU_MEAR/NotoSans-Thin.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}